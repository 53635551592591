.inactive {
  color: #999999 !important;
  font-size: 1.25rem;
  font-weight: medium;
  font-family: "Muli Bold";
}

.circle {
  background-color: #e6e7eb;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
}

.default {
  background-color: #e6e7eb;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  color: #6a7388;
}

.active {
  background-color: #1e5eff;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
  font-size: 14px;
}

.completed {
  background-color: #15a251;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.line {
  background-color: #e6e8eb;
  width: 72px;
  height: 1px;
  align-self: center;
}
