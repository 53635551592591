.modal.slideover-panel .modal-dialog{
    position: fixed;
    margin: auto;
    height: 100% !important;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  
  .modal.slideover-panel.fade .modal-dialog {
    right: 0;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal.slideover-panel.fade.in .modal-dialog {
    right: 0;
  }