.custom-badge-recs {
    border-radius: 4px;
    background-color: #f2f3f5;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    font-size: 12px;
  }