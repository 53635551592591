.calendar-container {
  width: 90%;
  border: 1px solid #E6E8EB;
  border-radius: 8px;
  box-shadow: none !important;
  margin: auto !important;
  width: 90%;
  padding: 24px 8px 12px 8px;
}

div.react-calendar {
  width: 100%;
  border: none !important;
  padding:0 !important;
  margin:0 !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #CDD0D7 !important
}

.react-calendar__tile--active {
  background-color: #fff !important;
  color: #06122E
}


.react-calendar__tile--active > .date-tile {
  border: 2px solid #6A7388;
  padding:4px !important;
}

.react-calendar__tile--active > .DRAFT-date-tile {
  border: 2px solid #1E5EFF;
  padding:4px !important;
}

.react-calendar__tile--active > .PENDING-date-tile {
  border: 2px solid #C77800;
  padding:4px !important;
}

.react-calendar__tile--active > .APPROVED-date-tile {
  border: 2px solid #15A251;
  padding:4px !important;
}

.react-calendar__tile--active > .SKIPPED-date-tile {
  border: 2px solid #B5B9C4;
  padding:4px !important;
}

.react-calendar__tile--active > .RESTRICTED-date-tile {
  border: 2px solid #B5B9C4;
  padding:4px !important;
}

.react-calendar__tile--active > .IGNORED-date-tile {
  border: 2px solid #B5B9C4;
  padding:4px !important;
}

.react-calendar__tile--active > .CANCELLED-date-tile {
  border: 2px solid #6A7388;
  padding:4px;
}

// TO BE REVIEWED

div.react-calendar__month-view__weekdays__weekday {
  text-transform: none;
}

div.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
  cursor: default;
}

.calendar-open-rec {
  background-color: #1e5eff;
  color: white;
  font-weight: bold;
}

.calendar-pending-rec {
  background-color: #F99600;
  color: white;
  font-weight: bold;
}

.calendar-completed-rec {
  background-color: #15A251;
  color: white;
  font-weight: bold;
}

.react-calendar__navigation__label__labelText {
  //font-weight: 600 !important;
}

.today-button-container {
  margin-left:45%;
}