.tag {
  background: #f2f3f5;
  border-radius: 4px;

  padding-left: 8px;
  padding-right: 8px;

  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;

  display: inline-block;
}

.tag-close-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.tags-editor {
  padding-left: 17px;
}

// This is needed when using column autoHeight and custom editor
.ag-theme-alpine .ag-cell-inline-editing {
  height: auto !important;
}
