.roundCheckbox {
  position: relative;
}

.roundCheckbox label {
  appearance: none;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  //outline: none;
  cursor: pointer;
  background-color: #e6e8eb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.roundCheckbox input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

.roundCheckbox input[type="checkbox"]:checked + label {
  background-color: #d2dfff;
  position: relative;
}
.dropdownList {
  max-height: 280px !important;
  overflow-y: auto !important;
}
