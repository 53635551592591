.toolbar-button {
    padding-bottom: 6px;
    padding-left: 6px;
    margin-right: 6px;
    border: 1px solid #CDD0D7;
    border-radius: 4px;
    background: #fff;
    width: 28px;
    height:28px;
}

.disabled-toolbar-button {
    padding-bottom: 6px;
    padding-left: 6px;
    margin-right: 6px;
    border: 1px solid #E6E8EB;
    border-radius: 4px;
    background: #fff;
    width: 28px;
    height:28px;
}

.toolbar-button:hover {
    background: #E6E8EB;
    cursor:pointer;
}