.dropdown-button-container {
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    height: 2.25rem;
    cursor: pointer;
    font-size: small;
  }

  .flexiInput-manual-input {
    position: relative;
    left: 0.5rem;
    border: none;
    background: rgba(0, 0, 0, 0);
  }

.dropdown-button-container:focus-within {
    border: 2px solid #1e5eff;
  }

  .dropdown-sizing {
    max-height: 45vh;
    overflow-y: scroll;
  }

  input:focus{
    outline: none;
}