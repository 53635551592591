.baselayout-main {
  transition:0.5s
}

.sidePanel {
  height: 90%;
  width: 0px;
  position: fixed;
  top: 5rem;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  border-left: 1px solid #E6E8EB;
  overflow-y: scroll;
  }

  #sidePanelButton {
    color:#fff;
    position: absolute;
    bottom: 5%;
    border-radius: 100%;
    background-color: #1E5EFF;
    right: 40px;
    z-index: 1000;
    transition: 0.5s;
    width:30px;
    height:30px;
    padding-left: 6px;
    cursor: pointer;
  }