#recSidePanel {
    width: 60px;
    right: 0px;
}

#rowGroupDetailsPanel {
    width: 0px;
    right: 60px;
    visibility:hidden
}

#recDetailsPanel {
    width: 60px;
    right: 0px;
}

.side-panel-style {
    height: 90%;
    position: fixed;
    top: 5rem;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    overflow-y: scroll;
    border-left: 1px solid #E6E8EB;
}