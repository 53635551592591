.ag-theme-alpine {
  --ag-data-color: #394561;
}

.muli-font-family {
  font-family: "Muli Regular", sans-serif !important;
}

.manually-matched-row {
  background-color: hsla(36, 100%, 79%, 0.3) !important;
}