.menuContent {
  background-color: white;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.disabled {
  opacity: 0.25;
   pointer-events: none;
}
    
.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.dropdownItem{
  padding-bottom: 11px;
}
